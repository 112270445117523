/* eslint-disable jsx-a11y/anchor-is-valid */
/* eslint-disable react/jsx-one-expression-per-line */
import React, { useEffect } from "react";
import PropTypes from "prop-types";
import Typography from "root/components/Typography";
import { useTranslation } from "react-i18next";
import legalStyles from "../../sections/LegalHero/index.module.css";
import LegalDocument from "../legal-document";

function PrivacyPolicy({ launcher }) {
  const { t, i18n } = useTranslation();

  useEffect(() => {
    i18n.changeLanguage("pt-PT");
  }, []);

  return (
    <LegalDocument
      launcher={launcher}
      title={t("pages.privacy.label")}
      bodyTitle={t("pages.privacy.title")}
      currentPage="privacy"
    >
      <Typography variant="h2" color="yellow" weight="medium">
        Sioslife – For Younger Spirits (Sioslife S.A.)
      </Typography>
      <div className={legalStyles.description}>
        <Typography color="black">
          A presente Política de Privacidade e Proteção de Dados Pessoais,
          aplica-se genericamente à recolha e tratamento dos dados pessoais
          fornecidos pelo Utilizador que aceda a qualquer plataforma Sioslife,
          designadamente, os recolhidos através dos formulários, documentos ou
          outros meios destinados a permitir o contacto com a Sioslife S.A.,
          pessoa coletiva 513107517, matriculada na Conservatória do Registo
          Comercial de Braga, sob o mesmo número, com sede na Rua Eng. Nuno
          Álvares Pereira, nº 33, 4710 915 Braga, e com o capital social de
          61.745,71 EUR, doravante a &quot;Sioslife&quot;, aplicando-se ainda ao
          exercício de direitos, pelo Utilizador, relativamente a estes dados,
          nos termos da legislação aplicável.
        </Typography>
      </div>
      <div className={legalStyles.description}>
        <Typography color="black">
          Esta Política reveste-se de carácter geral, pelo que a informação que
          nela é prestada pode ser complementada ou afastada, total ou
          parcialmente, por outras políticas, contratos, avisos ou informações
          de carácter mais específico que eventualmente tenham sido ou venham a
          ser disponibilizadas no contexto de certos tipos de tratamento de
          dados pessoais, ou de relação comercial entre a Sioslife e o
          Utilizador.
        </Typography>
      </div>
      <br />
      <br />
      <br />
      <Typography variant="h3" color="yellow" weight="medium">
        1. Introdução
      </Typography>
      <div className={legalStyles.description}>
        <Typography color="black">
          Obrigado por utilizar os sistemas Sioslife!
        </Typography>
      </div>
      <div className={legalStyles.description}>
        <Typography color="black">
          Na Sioslife, queremos dar-lhe a melhor experiência possível de
          utilização dos nossos serviços. É precisamente com esse efeito que
          precisamos de compreender os seus hábitos de utilização dos nossos
          sistemas, possibilitando personalizar e melhorar progressivamente a
          sua experiência connosco. Nesse sentido, pretendemos explicar, de
          forma transparente, porquê e como recolhemos, partilhamos, armazenamos
          e utilizamos os seus dados pessoais, indicando-lhe, simultaneamente,
          as formas de controlo e possibilidades de escolha de que dispõe sobre
          o momento e a forma como opta partilhar os seus dados pessoais.
        </Typography>
      </div>
      <div className={legalStyles.description}>
        <Typography color="black">
          Na presente Política de Privacidade, ou, simplesmente,
          &quot;Política&quot;, explicaremos, ao detalhe, todas as informações a
          ter em conta na gestão dos seus dados pessoais.
        </Typography>
      </div>
      <br />
      <br />
      <br />
      <Typography variant="h3" color="yellow" weight="medium">
        2. Sobre este documento
      </Typography>
      <div className={legalStyles.description}>
        <Typography color="black">
          A presente &quot;Política&quot; define os detalhes essenciais
          relativos à sua relação de dados pessoais com a Sioslife. A Política
          aplica-se a todos os serviços Sioslife e quaisquer serviços associados
          à sua utilização (&quot;Serviços Sioslife&quot;). As condições que
          regem o uso dos Serviços Sioslife encontram-se definidos no nosso
          documento Termos e Condições de Uso (os &quot;Termos e Condições de
          Uso&quot;).
        </Typography>
      </div>
      <div className={legalStyles.description}>
        <Typography color="black">
          Sempre que sejam desenvolvidos, oferecidos ou disponibilizados novos
          serviços ou produtos adicionais, nos casos em que a introdução dos
          mesmos resulte em qualquer alteração material na forma como recolhemos
          ou tratamos os dados pessoais, comprometemo-nos a fornecer todas as
          novas informações, condições e/ou políticas adicionais. Nos casos em
          que tal não seja necessário, e salvo indicação contrária, os novos
          serviços ou produtos encontrar-se-ão sujeitos à presente Política.
        </Typography>
      </div>
      <div className={legalStyles.description}>
        <Typography color="black">
          Assim, a presente Política tem como objetivos:
        </Typography>
      </div>
      <div className={legalStyles.description}>
        <ol>
          <li>
            <Typography color="black">
              1. Garantir a sua compreensão sobre os dados pessoais que
              recolhemos, as razões para esta recolha e utilização e com quem os
              partilhamos;
            </Typography>
          </li>
          <li>
            <Typography color="black">
              2. Explicar a forma como utilizamos os dados pessoais que partilha
              connosco, nomeadamente para lhe garantir uma cada vez melhor
              experiência de utilização;
            </Typography>
          </li>
          <li>
            <Typography color="black">
              3. Explicar os seus direitos e opções em relação aos dados
              pessoais que recolhemos e tratamos e a forma como pretendemos
              proteger a sua privacidade.
            </Typography>
          </li>
        </ol>
      </div>
      <div className={legalStyles.description}>
        <Typography color="black">
          Esperamos que este documento seja capaz de esclarecer todas as
          questões relacionadas com a utilização de dados pessoais, nos serviços
          Sioslife. Para obter mais informações, ou simplesmente esclarecer
          outras questões, saiba como pode entrar em contacto connosco na Secção
          13 &quot;Como pode contactar-nos?&quot;. Em alternativa, caso não
          concorde com a presente Política, recorde que poderá optar por não
          usar os Serviços Sioslife.
        </Typography>
      </div>
      <br />
      <br />
      <br />
      <Typography variant="h3" color="yellow" weight="medium">
        3. Possibilidades de escolha e formas de controlo
      </Typography>
      <div className={legalStyles.description}>
        <Typography color="black">
          O Regulamento Geral sobre a Proteção de Dados ou &quot;RGPD&quot;
          confere determinados direitos às pessoas singulares no que diz
          respeito aos seus dados pessoais. Os direitos conferidos às pessoas
          singulares, sem prejuízo das limitações previstas na legislação
          aplicável, são os seguintes:
        </Typography>
      </div>
      <div className={legalStyles.description}>
        <ul>
          <li>
            <Typography color="black">
              Direito de acesso - direito de ser informado e solicitar acesso
              aos seus dados pessoais cujo tratamento efetuamos;
            </Typography>
          </li>
          <li>
            <Typography color="black">
              Direito de retificação - direito de requerer que alteremos ou
              atualizemos os seus dados pessoais quando estes estiverem
              incorretos ou incompletos;
            </Typography>
          </li>
          <li>
            <Typography color="black">
              Direito de apagamento - direito de solicitar que eliminemos os
              seus dados pessoais dos nossos serviços e sistemas;
            </Typography>
          </li>
          <li>
            <Typography color="black">
              Direito de limitação - direito de pedir que cessemos, temporária
              ou permanentemente, de processar todos ou alguns dos seus dados
              pessoais;
            </Typography>
          </li>
          <li>
            <Typography color="black">
              Direito de oposição - direto, a qualquer momento, de se opor ao
              tratamento dos seus dados pessoais por motivos relacionados com a
              situação particular; ou, direito de se opor ao tratamento dos seus
              dados pessoais para fins de marketing direto;
            </Typography>
          </li>
          <li>
            <Typography color="black">
              Direito à portabilidade de dados - direito de solicitar uma cópia
              dos seus dados pessoais em formato eletrónico e direito de os
              transmitir para efeitos de utilização no serviço de terceiros;
            </Typography>
          </li>
          <li>
            <Typography color="black">
              Direito de não-sujeição a uma tomada de decisão automatizada -
              direito de não estar sujeito a uma decisão baseada unicamente na
              tomada de decisão automatizada, incluindo no que diz respeito à
              definição de perfis, caso a decisão produza um efeito jurídico ou
              um efeito igualmente significativo sobre o utilizador;
            </Typography>
          </li>
        </ul>
        <div className={legalStyles.description}>
          <Typography color="black">
            Em caso de subsistência de dúvidas sobre a sua privacidade, os seus
            direitos ou sobre o exercício dos mesmos, entre em contacto com o
            nosso Responsável de Proteção de Dados Pessoais através do
            formulário &quot;Entre em contacto connosco&quot; no nosso website.
            Em caso de dúvidas quanto ao nosso método de tratamento dos seus
            dados pessoais, gostaríamos que continuasse a trabalhar connosco no
            sentido de as esclarecer. No entanto, pode igualmente contactar e
            exercer o seu direito de apresentação de reclamação junto da sua
            Autoridade de Proteção de Dados local.
          </Typography>
        </div>
      </div>
      <br />
      <br />
      <br />
      <Typography variant="h3" color="yellow" weight="medium">
        4. Que dados pessoais recolhemos?
      </Typography>
      <div className={legalStyles.description}>
        <Typography color="black">
          Nas tabelas abaixo, elencamos as categorias de dados pessoais que
          recolhemos e usamos, relativamente aos utilizadores, e a forma como os
          recolhemos:
        </Typography>
      </div>
      <div className={legalStyles.description}>
        <Typography color="black">
          A tabela infra descreve os dados pessoais recolhidos quando do momento
          da primeira ligação aos Serviços Sioslife:
        </Typography>
      </div>
      <div className={legalStyles.description}>
        <table>
          <tr>
            <td>
              <Typography color="black" variant="smallBody" weight="medium">
                Categorias de dados pessoais
              </Typography>
            </td>
            <td>
              <Typography color="black" variant="smallBody" weight="medium">
                Descrição da categoria
              </Typography>
            </td>
          </tr>
          <tr>
            <td>
              <Typography color="black" variant="smallBody">
                Dados do Utilizador
              </Typography>
            </td>
            <td>
              <Typography color="black" variant="smallBody">
                Estes são os dados pessoais fornecidos pelo utilizador ou
                recolhidos pela Sioslife para permitir que o utilizador utilize
                os Serviços Sioslife. Os referidos dados pessoais podem incluir
                o nome de utilizador, endereço eletrónico, número de telefone,
                data de nascimento, sexo, morada e país.
              </Typography>
              <br />
              <Typography color="black" variant="smallBody">
                Alguns dos dados pessoais solicitados são necessários para criar
                a conta do utilizador. O utilizador dispõe igualmente da opção
                de nos facultar dados pessoais adicionais para tornar a sua
                conta mais personalizada.
              </Typography>
            </td>
          </tr>
        </table>
      </div>
      <div className={legalStyles.description}>
        <Typography color="black">
          A tabela infra destaca os dados pessoais recolhidos de acordo com a
          categoria de utilizador:
        </Typography>
      </div>
      <div className={legalStyles.description}>
        <table>
          <tr>
            <td>
              <Typography color="black" variant="smallBody" weight="medium">
                Categorias de dados pessoais
              </Typography>
            </td>
            <td>
              <Typography color="black" variant="smallBody" weight="medium">
                Descrição da categoria
              </Typography>
            </td>
          </tr>
          <tr>
            <td>
              <Typography color="black" variant="smallBody">
                Dados de Utilização do &quot;Sénior&quot;
              </Typography>
            </td>
            <td>
              <Typography color="black" variant="smallBody">
                Estes são os dados pessoais recolhidos sobre si quando acede
                e/ou utiliza os Serviços Sioslife, nomeadamente:
              </Typography>
              <ul style={{ marginTop: "12px" }}>
                <li>
                  <Typography color="black" variant="smallBody">
                    Informação sobre a sua subscrição de Serviços Sioslife;
                  </Typography>
                </li>
                <li>
                  <Typography color="black" variant="smallBody">
                    Informação sobre as suas interações com os Serviços
                    Sioslife, tais como as preferências, aplicações utilizadas,
                    conteúdos personalizados para si, partilhas de conteúdos
                    efetuadas ou recebidas;
                  </Typography>
                </li>
                <li>
                  <Typography color="black" variant="smallBody">
                    Conclusões extraídas sobre os seus interesses e preferências
                    com base na sua utilização dos Serviço Sioslife;
                  </Typography>
                </li>
                <li>
                  <Typography color="black" variant="smallBody">
                    Conteúdos do Utilizador (conforme definido nos [Termos e
                    Condições de Uso] (TC)) que publicar nos Serviços Sioslife,
                    tais como fotografias ou vídeos. Note que apenas iremos
                    aceder à sua câmara ou às fotografias do seu dispositivo no
                    caso de nos conceder autorização para o efeito, sendo certo
                    que apenas teremos acesso a imagens que decida
                    especificamente partilhar connosco, bem como a metadados
                    relacionados com as referidas imagens, tais como o tipo de
                    ficheiro e o tamanho da imagem. Em caso algum acederemos a
                    qualquer tipo de dados que não tenham sido partilhados
                    através dos Serviços Sioslife;
                  </Typography>
                </li>
                <li>
                  <Typography color="black" variant="smallBody">
                    Alguns dados pessoais técnicos, que podem incluir:
                  </Typography>
                  <ul>
                    <li>
                      <Typography color="black" variant="smallBody">
                        informação de URL;
                      </Typography>
                    </li>
                    <li>
                      <Typography color="black" variant="smallBody">
                        identificadores online, incluindo dados de cookies e
                        endereços IP;
                      </Typography>
                    </li>
                    <li>
                      <Typography color="black" variant="smallBody">
                        informação sobre os tipos de dispositivos utilizados,
                        como IDs de dispositivos exclusivos, tipo de conexão de
                        rede (por exemplo, Wi-Fi, 3G, LTE, Bluetooth),
                        desempenho do prestador, da rede e do dispositivo, tipo
                        de navegador, idioma, informações para gestão de
                        direitos digitais, sistema operativo e versão da
                        aplicação Sioslife;
                      </Typography>
                    </li>
                    <li>
                      <Typography color="black" variant="smallBody">
                        atributos de dispositivos na sua rede Wi-Fi disponíveis
                        para se conectarem aos Serviços Sioslife (como
                        altifalantes);
                      </Typography>
                    </li>
                    <li>
                      <Typography color="black" variant="smallBody">
                        a sua localização não precisa, que pode ser derivada ou
                        inferida a partir de determinados dados técnicos (por
                        exemplo, do seu endereço IP, da configuração de idioma
                        do seu dispositivo ou da moeda de pagamento), para
                        cumprir os requisitos geográficos dos nossos contratos
                        de licenciamento e fornecer conteúdo e publicidade
                        personalizados;
                      </Typography>
                    </li>
                    <li>
                      <Typography color="black" variant="smallBody">
                        dados de sensores móveis gerados por movimento ou
                        orientação (por exemplo, acelerómetro ou giroscópio)
                        necessários para lhe fornecer características
                        específicas dos Serviços Sioslife;
                      </Typography>
                    </li>
                  </ul>
                </li>
              </ul>
            </td>
          </tr>
          <tr>
            <td>
              <Typography color="black" variant="smallBody">
                Dados de Utilização do &quot;Caregiver&quot;
              </Typography>
            </td>
            <td>
              <Typography color="black" variant="smallBody">
                Estes são os dados pessoais recolhidos sobre si quando acede
                e/ou utiliza os Serviços Sioslife, nomeadamente:
              </Typography>
              <ul>
                <li>
                  <Typography color="black" variant="smallBody">
                    Informação sobre a sua subscrição de Serviços Sioslife;
                  </Typography>
                </li>
                <li>
                  <Typography color="black" variant="smallBody">
                    Conclusões extraídas sobre os seus interesses e preferências
                    com base na sua utilização dos Serviço Sioslife;
                  </Typography>
                </li>
                <li>
                  <Typography color="black" variant="smallBody">
                    Conteúdos do Utilizador (conforme definido nos [Termos e
                    Condições de Uso] (TC)) que publicar nos Serviços Sioslife,
                    tais como fotografias ou vídeos. Note que apenas iremos
                    aceder à sua câmara ou às fotografias do seu dispositivo no
                    caso de nos conceder autorização para o efeito, sendo certo
                    que apenas teremos acesso a imagens que decida
                    especificamente partilhar connosco, bem como a metadados
                    relacionados com as referidas imagens, tais como o tipo de
                    ficheiro e o tamanho da imagem. Em caso algum acederemos a
                    qualquer tipo de dados que não tenham sido partilhados
                    através dos Serviços Sioslife;
                  </Typography>
                </li>
                <li>
                  <Typography color="black" variant="smallBody">
                    Alguns dados pessoais técnicos, que podem incluir:
                  </Typography>
                  <ul>
                    <li>
                      <Typography color="black" variant="smallBody">
                        informação de URL;
                      </Typography>
                    </li>
                    <li>
                      <Typography color="black" variant="smallBody">
                        identificadores online, incluindo dados de cookies e
                        endereços IP;
                      </Typography>
                    </li>
                    <li>
                      <Typography color="black" variant="smallBody">
                        informação sobre os tipos de dispositivos utilizados,
                        como IDs de dispositivos exclusivos, tipo de conexão de
                        rede (por exemplo, Wi-Fi, 3G, LTE, Bluetooth),
                        desempenho do prestador, da rede e do dispositivo, tipo
                        de navegador, idioma, informações para gestão de
                        direitos digitais, sistema operativo e versão da
                        aplicação Sioslife;
                      </Typography>
                    </li>
                    <li>
                      <Typography color="black" variant="smallBody">
                        atributos de dispositivos na sua rede Wi-Fi disponíveis
                        para se conectarem aos Serviços Sioslife (como
                        altifalantes);
                      </Typography>
                    </li>
                    <li>
                      <Typography color="black" variant="smallBody">
                        a sua localização não precisa, que pode ser derivada ou
                        inferida a partir de determinados dados técnicos (por
                        exemplo, do seu endereço IP, da configuração de idioma
                        do seu dispositivo ou da moeda de pagamento), para
                        cumprir os requisitos geográficos dos nossos contratos
                        de licenciamento e fornecer conteúdo e publicidade
                        personalizados;
                      </Typography>
                    </li>
                  </ul>
                </li>
              </ul>
            </td>
          </tr>
          <tr>
            <td>
              <Typography color="black" variant="smallBody">
                Dados de Verificação do Plano
              </Typography>
            </td>
            <td>
              <Typography color="black" variant="smallBody">
                Poderemos utilizar uma aplicação de mapeamento de terceiros
                (como o Google Maps) e / ou o serviço de localização do
                dispositivo utilizador no sentido de ajudá-lo a verificar a
                respetiva morada. Os referidos dados são recolhidos com a única
                finalidade de verificação da elegibilidade para utilizar o nosso
                plano e não são usados para fins publicitários ou qualquer outra
                finalidade.
              </Typography>
            </td>
          </tr>
        </table>
      </div>
      <div className={legalStyles.description}>
        <Typography color="black">
          A tabela infra descreve os dados pessoais que decide facultar-nos e
          que nos permitem fornecer recursos / funcionalidades dos nossos
          serviços, independentemente do tipo de utilizador:
        </Typography>
      </div>
      <div className={legalStyles.description}>
        <table>
          <tr>
            <td>
              <Typography color="black" variant="smallBody" weight="medium">
                Categorias de dados pessoais
              </Typography>
            </td>
            <td>
              <Typography color="black" variant="smallBody" weight="medium">
                Descrição da categoria
              </Typography>
            </td>
          </tr>
          <tr>
            <td>
              <Typography color="black" variant="smallBody">
                Dados de Voz
              </Typography>
            </td>
            <td>
              <Typography color="black" variant="smallBody">
                A Sioslife recolhe os dados de voz do utilizador após obtenção
                de autorização por parte deste último para efeitos de
                fornecimento de recursos e funcionalidades adicionais, como
                sejam a interação com os Serviços Sioslife através da voz.
              </Typography>
            </td>
          </tr>
          <tr>
            <td>
              <Typography color="black" variant="smallBody">
                Dados de Vídeo
              </Typography>
            </td>
            <td>
              <Typography color="black" variant="smallBody">
                A Sioslife recolhe os dados de vídeo do utilizador após obtenção
                de autorização por parte deste último para efeitos de
                fornecimento de recursos e funcionalidades adicionais, como
                sejam a interação com os Serviços Sioslife através da voz
              </Typography>
            </td>
          </tr>
          <tr>
            <td>
              <Typography color="black" variant="smallBody">
                Dados de Pagamento e Compra
              </Typography>
            </td>
            <td>
              <Typography color="black" variant="smallBody">
                A Sioslife pode recolher determinados dados pessoais no caso de
                o utilizador subscrever um Período Experimental ou qualquer uma
                das nossas Assinaturas Pagas (conforme definido nos Termos e
                Condições de Uso), ou efetuar outras compras através dos
                Serviços Sioslife. Os dados pessoais exatos objeto de recolha
                variam em função do método de pagamento (por exemplo,
                diretamente através da operadora de telemóvel do utilizador ou
                através de fatura), mas incluem informação como seja:
              </Typography>
              <ul style={{ marginTop: "12px" }}>
                <li>
                  <Typography color="black" variant="smallBody">
                    Nome;
                  </Typography>
                </li>
                <li>
                  <Typography color="black" variant="smallBody">
                    Data de nascimento;
                  </Typography>
                </li>
                <li>
                  <Typography color="black" variant="smallBody">
                    Tipo de cartão de crédito ou débito, data de validade e
                    certos dígitos do número do cartão do utilizador;
                  </Typography>
                </li>
                <li>
                  <Typography color="black" variant="smallBody">
                    Código postal;
                  </Typography>
                </li>
                <li>
                  <Typography color="black" variant="smallBody">
                    Número de telemóvel; e
                  </Typography>
                </li>
                <li>
                  <Typography color="black" variant="smallBody">
                    Detalhes do histórico de compras e pagamentos do utilizador.
                  </Typography>
                </li>
              </ul>
            </td>
          </tr>
          <tr>
            <td>
              <Typography color="black" variant="smallBody">
                Dados Relativos a Concursos, Inquéritos e Sorteios
              </Typography>
            </td>
            <td>
              <Typography color="black" variant="smallBody">
                Sempre que preencher um formulário, responder a um inquérito ou
                questionário, ou participar num concurso, procederemos à recolha
                dos dados pessoais facultados.
              </Typography>
            </td>
          </tr>
        </table>
      </div>
      <div className={legalStyles.description}>
        <Typography color="black">
          A tabela infra descreve os dados pessoais recolhidos a partir de
          terceiros. Recolhemos os dados pessoais dos utilizadores a partir de
          vários terceiros. Estas fontes (terceiros) variam ao longo do tempo e
          incluem:
        </Typography>
      </div>
      <div className={legalStyles.description}>
        <table>
          <tr>
            <td>
              <Typography color="black" variant="smallBody" weight="medium">
                Categorias das fontes (terceiros)
              </Typography>
            </td>
            <td>
              <Typography color="black" variant="smallBody" weight="medium">
                Descrição da categoria
              </Typography>
            </td>
          </tr>
          <tr>
            <td>
              <Typography color="black" variant="smallBody">
                Parceiros de autenticação
              </Typography>
            </td>
            <td>
              <Typography color="black" variant="smallBody">
                No caso de se registar ou aceder aos Serviços Sioslife
                utilizando credenciais de terceiros (por exemplo, Facebook ou
                Google), procederemos à importação da informação a partir de
                terceiros para efeitos de criação da sua conta connosco.
              </Typography>
            </td>
          </tr>
          <tr>
            <td>
              <Typography color="black" variant="smallBody">
                Parceiros de serviços técnicos
              </Typography>
            </td>
            <td>
              <Typography color="black" variant="smallBody">
                Trabalhamos com parceiros de serviços técnicos que nos facultam
                determinados dados, tais como o mapeamento de endereços IP em
                matéria de dados de localização não-precisa (por exemplo,
                cidade, estado), que nos permitem fornecer os Serviços Sioslife,
                conteúdo e funcionalidades.
              </Typography>
            </td>
          </tr>
          <tr>
            <td>
              <Typography color="black" variant="smallBody">
                Parceiros de pagamento
              </Typography>
            </td>
            <td>
              <Typography color="black" variant="smallBody">
                Se optar por pagar por um serviço ou funcionalidade por fatura,
                poderemos receber dados dos nossos parceiros de pagamento para
                efeitos de lhe enviarmos faturas, processar o seu pagamento e
                fornecer produtos.
              </Typography>
            </td>
          </tr>
          <tr>
            <td>
              <Typography color="black" variant="smallBody">
                Anunciantes e outros parceiros de publicidade
              </Typography>
            </td>
            <td>
              <Typography color="black" variant="smallBody">
                Poderemos obter determinados dados do utilizador tais como ID do
                cookie, ID do dispositivo móvel ou endereço eletrónico, bem como
                conclusões sobre os interesses e preferências de certos
                anunciantes e parceiros de publicidade, o que nos permite
                apresentar anúncios mais relevantes e medir a respetiva
                eficácia.
              </Typography>
            </td>
          </tr>
        </table>
      </div>
      <br />
      <br />
      <br />
      <Typography variant="h3" color="yellow" weight="medium">
        5. Para que fins utilizamos os seus dados pessoais?
      </Typography>
      <div className={legalStyles.description}>
        <Typography color="black">
          Quando utiliza ou interage com os Serviços Sioslife, usamos uma
          variedade de tecnologias para processar os dados pessoais que
          recolhemos do utilizador por várias ordens de razões. Na tabela infra
          apresentam-se os motivos pelos quais tratamos os dados pessoais dos
          utilizadores, os fundamentos legais em que nos baseamos para podermos
          processar os referidos dados pessoais e as categorias de dados
          pessoais (identificadas na Secção anterior) utilizadas para tais
          finalidades:
        </Typography>
      </div>
      <div className={legalStyles.description} style={{ overflowX: "auto" }}>
        <table>
          <tr>
            <td>
              <Typography color="black" variant="smallBody" weight="medium">
                Descrição dos motivos pelos quais processamos os dados pessoais
                do utilizador (&quot;finalidade do tratamento&quot;)
              </Typography>
            </td>
            <td>
              <Typography color="black" variant="smallBody" weight="medium">
                Base Legal para a finalidade do tratamento
              </Typography>
            </td>
            <td>
              <Typography color="black" variant="smallBody" weight="medium">
                Categorias de dados pessoais utilizadas pela Sioslife para a
                finalidade do tratamento
              </Typography>
            </td>
          </tr>
          <tr>
            <td>
              <Typography color="black" variant="smallBody">
                Prestar e personalizar os Serviços Sioslife
              </Typography>
            </td>
            <td>
              <ul>
                <li>
                  <Typography color="black" variant="smallBody">
                    Execução de um Contrato
                  </Typography>
                </li>
                <li>
                  <Typography color="black" variant="smallBody">
                    Interesse Legítimo
                  </Typography>
                </li>
                <li>
                  <Typography color="black" variant="smallBody">
                    Consentimento
                  </Typography>
                </li>
              </ul>
            </td>
            <td>
              <ul>
                <li>
                  <Typography color="black" variant="smallBody">
                    Dados do Utilizador
                  </Typography>
                </li>
                <li>
                  <Typography color="black" variant="smallBody">
                    Dados de Utilização
                  </Typography>
                </li>
                <li>
                  <Typography color="black" variant="smallBody">
                    Dados de Pagamento e Compra
                  </Typography>
                </li>
                <li>
                  <Typography color="black" variant="smallBody">
                    Dados de Verificação do Plano
                  </Typography>
                </li>
                <li>
                  <Typography color="black" variant="smallBody">
                    Dados de Voz
                  </Typography>
                </li>
              </ul>
            </td>
          </tr>
          <tr>
            <td>
              <Typography color="black" variant="smallBody">
                Entender, diagnosticar, solucionar e corrigir problemas dos
                Serviços Sioslife
              </Typography>
            </td>
            <td>
              <ul>
                <li>
                  <Typography color="black" variant="smallBody">
                    Execução de um Contrato
                  </Typography>
                </li>
                <li>
                  <Typography color="black" variant="smallBody">
                    Interesse Legítimo
                  </Typography>
                </li>
              </ul>
            </td>
            <td>
              <ul>
                <li>
                  <Typography color="black" variant="smallBody">
                    Dados do Utilizador
                  </Typography>
                </li>
                <li>
                  <Typography color="black" variant="smallBody">
                    Dados de Utilização
                  </Typography>
                </li>
              </ul>
            </td>
          </tr>
          <tr>
            <td>
              <Typography color="black" variant="smallBody">
                Avaliar e desenvolver novas funcionalidades, tecnologias e
                melhorias para os Serviços Sioslife
              </Typography>
            </td>
            <td>
              <ul>
                <li>
                  <Typography color="black" variant="smallBody">
                    Consentimento
                  </Typography>
                </li>
                <li>
                  <Typography color="black" variant="smallBody">
                    Interesse Legítimo
                  </Typography>
                </li>
              </ul>
            </td>
            <td>
              <ul>
                <li>
                  <Typography color="black" variant="smallBody">
                    Dados do Utilizador
                  </Typography>
                </li>
                <li>
                  <Typography color="black" variant="smallBody">
                    Dados de Utilização
                  </Typography>
                </li>
                <li>
                  <Typography color="black" variant="smallBody">
                    Dados de Voz
                  </Typography>
                </li>
              </ul>
            </td>
          </tr>
          <tr>
            <td>
              <Typography color="black" variant="smallBody">
                Finalidades de marketing, promoção e publicidade
              </Typography>
            </td>
            <td>
              <ul>
                <li>
                  <Typography color="black" variant="smallBody">
                    Consentimento
                  </Typography>
                </li>
                <li>
                  <Typography color="black" variant="smallBody">
                    Interesse Legítimo
                  </Typography>
                </li>
              </ul>
            </td>
            <td>
              <ul>
                <li>
                  <Typography color="black" variant="smallBody">
                    Dados do Utilizador
                  </Typography>
                </li>
                <li>
                  <Typography color="black" variant="smallBody">
                    Dados de Utilização
                  </Typography>
                </li>
                <li>
                  <Typography color="black" variant="smallBody">
                    Dados de Voz
                  </Typography>
                </li>
              </ul>
            </td>
          </tr>
          <tr>
            <td>
              <Typography color="black" variant="smallBody">
                Cumprir as obrigações legais e os pedidos de aplicação da lei
              </Typography>
            </td>
            <td>
              <ul>
                <li>
                  <Typography color="black" variant="smallBody">
                    Cumprimento de Obrigações Legais
                  </Typography>
                </li>
                <li>
                  <Typography color="black" variant="smallBody">
                    Interesse Legítimo
                  </Typography>
                </li>
              </ul>
            </td>
            <td>
              <ul>
                <li>
                  <Typography color="black" variant="smallBody">
                    Dados do Utilizador
                  </Typography>
                </li>
                <li>
                  <Typography color="black" variant="smallBody">
                    Dados de Utilização
                  </Typography>
                </li>
                <li>
                  <Typography color="black" variant="smallBody">
                    Dados de Pagamento e Compra
                  </Typography>
                </li>
                <li>
                  <Typography color="black" variant="smallBody">
                    Dados de Voz
                  </Typography>
                </li>
                <li>
                  <Typography color="black" variant="smallBody">
                    Dados relativos a Concursos, Inquéritos e Sorteios
                  </Typography>
                </li>
              </ul>
            </td>
          </tr>
          <tr>
            <td>
              <Typography color="black" variant="smallBody">
                Cumprir obrigações contratuais com terceiros (por exemplo,
                acordos de licenciamento), bem como adotar medidas adequadas em
                relação a relatórios de violação de propriedade intelectual e
                conteúdo inadequado
              </Typography>
            </td>
            <td>
              <ul>
                <li>
                  <Typography color="black" variant="smallBody">
                    Interesse Legítimo
                  </Typography>
                </li>
              </ul>
            </td>
            <td>
              <ul>
                <li>
                  <Typography color="black" variant="smallBody">
                    Dados do Utilizador
                  </Typography>
                </li>
                <li>
                  <Typography color="black" variant="smallBody">
                    Dados de Utilização
                  </Typography>
                </li>
                <li>
                  <Typography color="black" variant="smallBody">
                    Dados de Pagamento e Compra
                  </Typography>
                </li>
              </ul>
            </td>
          </tr>
          <tr>
            <td>
              <Typography color="black" variant="smallBody">
                Declarar, exercer ou defender um direito num processo judicial
              </Typography>
            </td>
            <td>
              <ul>
                <li>
                  <Typography color="black" variant="smallBody">
                    Interesse Legítimo
                  </Typography>
                </li>
              </ul>
            </td>
            <td>
              <ul>
                <li>
                  <Typography color="black" variant="smallBody">
                    Dados do Utilizador
                  </Typography>
                </li>
                <li>
                  <Typography color="black" variant="smallBody">
                    Dados de Utilização
                  </Typography>
                </li>
                <li>
                  <Typography color="black" variant="smallBody">
                    Dados de Pagamento e Compra
                  </Typography>
                </li>
                <li>
                  <Typography color="black" variant="smallBody">
                    Dados de Voz
                  </Typography>
                </li>
                <li>
                  <Typography color="black" variant="smallBody">
                    Dados relativos a Concursos, Inquéritos e Sorteios
                  </Typography>
                </li>
              </ul>
            </td>
          </tr>
          <tr>
            <td>
              <Typography color="black" variant="smallBody">
                Conduzir o planeamento de negócios, relatórios e previsões
              </Typography>
            </td>
            <td>
              <ul>
                <li>
                  <Typography color="black" variant="smallBody">
                    Interesse Legítimo
                  </Typography>
                </li>
              </ul>
            </td>
            <td>
              <ul>
                <li>
                  <Typography color="black" variant="smallBody">
                    Dados do Utilizador
                  </Typography>
                </li>
                <li>
                  <Typography color="black" variant="smallBody">
                    Dados de Utilização
                  </Typography>
                </li>
                <li>
                  <Typography color="black" variant="smallBody">
                    Dados de Pagamento e Compra
                  </Typography>
                </li>
              </ul>
            </td>
          </tr>
          <tr>
            <td>
              <Typography color="black" variant="smallBody">
                Processar o seu pagamento
              </Typography>
            </td>
            <td>
              <ul>
                <li>
                  <Typography color="black" variant="smallBody">
                    Cumprimento de Obrigações Legais
                  </Typography>
                </li>
                <li>
                  <Typography color="black" variant="smallBody">
                    Execução de um Contrato
                  </Typography>
                </li>
              </ul>
            </td>
            <td>
              <ul>
                <li>
                  <Typography color="black" variant="smallBody">
                    Dados do Utilizador
                  </Typography>
                </li>
                <li>
                  <Typography color="black" variant="smallBody">
                    Dados de Pagamento e Compra
                  </Typography>
                </li>
              </ul>
            </td>
          </tr>
          <tr>
            <td>
              <Typography color="black" variant="smallBody">
                Detetar fraudes, incluindo pagamentos fraudulentos e uso
                fraudulento dos Serviços Sioslife
              </Typography>
            </td>
            <td>
              <ul>
                <li>
                  <Typography color="black" variant="smallBody">
                    Cumprimento de Obrigações Legais
                  </Typography>
                </li>
                <li>
                  <Typography color="black" variant="smallBody">
                    Execução de um Contrato
                  </Typography>
                </li>
                <li>
                  <Typography color="black" variant="smallBody">
                    Interesse Legítimo
                  </Typography>
                </li>
              </ul>
            </td>
            <td>
              <ul>
                <li>
                  <Typography color="black" variant="smallBody">
                    Dados do Utilizador
                  </Typography>
                </li>
                <li>
                  <Typography color="black" variant="smallBody">
                    Dados de Utilização
                  </Typography>
                </li>
                <li>
                  <Typography color="black" variant="smallBody">
                    Dados de Pagamento e Compra
                  </Typography>
                </li>
              </ul>
            </td>
          </tr>
          <tr>
            <td>
              <Typography color="black" variant="smallBody">
                Conduzir pesquisas, concursos, inquéritos e sorteios
              </Typography>
            </td>
            <td>
              <ul>
                <li>
                  <Typography color="black" variant="smallBody">
                    Consentimento
                  </Typography>
                </li>
                <li>
                  <Typography color="black" variant="smallBody">
                    Execução de um Contrato
                  </Typography>
                </li>
                <li>
                  <Typography color="black" variant="smallBody">
                    Interesse Legítimo
                  </Typography>
                </li>
              </ul>
            </td>
            <td>
              <ul>
                <li>
                  <Typography color="black" variant="smallBody">
                    Dados do Utilizador
                  </Typography>
                </li>
                <li>
                  <Typography color="black" variant="smallBody">
                    Dados de Utilização
                  </Typography>
                </li>
                <li>
                  <Typography color="black" variant="smallBody">
                    Dados relativos a Concursos, Inquéritos e Sorteios
                  </Typography>
                </li>
              </ul>
            </td>
          </tr>
        </table>
      </div>
      <br />
      <br />
      <br />
      <Typography variant="h3" color="yellow" weight="medium">
        6. Como partilhamos os seus dados pessoais?
      </Typography>
      <div className={legalStyles.description}>
        <Typography color="black" weight="medium">
          Informação disponível a utilizadores das plataformas Sioslife
        </Typography>
      </div>
      <div className={legalStyles.description}>
        <Typography color="black">
          Estabelecemos categorias de destinatários dos dados pessoais
          recolhidos ou gerados através da sua utilização dos Serviços Sioslife.
          <br />
          Os dados pessoais descritos infra estarão sempre disponíveis nos
          Serviços Sioslife: o seu nome e / ou nome de utilizador, fotografia de
          perfil, círculo de contactos criados pelo utilizador e quem se
          registou o utilizador nos Serviços Sioslife, as informações públicas
          do utilizador.
        </Typography>
      </div>
      <div className={legalStyles.description}>
        <Typography color="black" weight="medium">
          Dados pessoais que o utilizador pode optar por partilhar
        </Typography>
      </div>
      <div className={legalStyles.description}>
        <Typography color="black">
          Os dados pessoais elencados em baixo serão partilhados apenas com as
          categorias de destinatários indicadas na tabela infra se:
        </Typography>
      </div>
      <div className={legalStyles.description}>
        <ul>
          <li>
            <Typography color="black">
              O utilizador optar por recorrer a uma funcionalidade específica
              dos Serviços Sioslife na qual a partilha de dados pessoais
              específicos seja necessária para o uso adequado das
              funcionalidades dos Serviços Sioslife;
            </Typography>
          </li>
          <li>
            <Typography color="black">
              O utilizador conceder autorização para a partilha dos seus dados
              pessoais, por exemplo, selecionando a configuração apropriada dos
              Serviços Sioslife ou autorizando a Sioslife através do mecanismo
              de consentimento disponibilizado por esta (normalmente, a
              declaração de consentimento na primeira utilização dos nossos
              serviços);
            </Typography>
          </li>
        </ul>
      </div>
      <div className={legalStyles.description}>
        <table>
          <tr>
            <td>
              <Typography color="black" variant="smallBody" weight="medium">
                Categorias de Destinatários
              </Typography>
            </td>
            <td>
              <Typography color="black" variant="smallBody" weight="medium">
                Justificação para a Partilha de Dados Pessoais
              </Typography>
            </td>
          </tr>
          <tr>
            <td>
              <Typography color="black" variant="smallBody">
                Aplicações de terceiros e dispositivos conectados ao utilizador
              </Typography>
            </td>
            <td>
              <Typography color="black" variant="smallBody">
                No caso de o utilizador conectar a sua conta Sioslife a uma
                aplicação e / ou dispositivo(s) de terceiros, tais como as redes
                sociais, áudio, televisão ou plataformas e serviços automóveis,
                a Sioslife partilha dados técnicos com o operador da aplicação
                em causa e / ou dispositivo de terceiros para conectar a conta
                Sioslife do utilizador e / ou facilitar a reprodução dos
                Serviços Sioslife. Relativamente a alguns terceiros que possam
                solicitar ou exigir que partilhemos a informação do utilizador
                com os mesmos, a sua autorização será solicitada antes de
                fornecermos qualquer informação.
              </Typography>
            </td>
          </tr>
          <tr>
            <td>
              <Typography color="black" variant="smallBody">
                Partilha de dados entre &quot;sénior&quot; e
                &quot;caregiver&quot;
              </Typography>
            </td>
            <td>
              <Typography color="black" variant="smallBody">
                Caso algum dos utilizadores pretenda estabelecer contacto em
                tempo real ou partilhar conteúdos como mensagens escritas, voz,
                vídeos ou outros elementos multimédia. Caso algum dos
                &quot;caregivers&quot; pretenda personalizar ou ajudar nas
                configurações remotas do dispositivo utilizado pelo sénior.
              </Typography>
            </td>
          </tr>
        </table>
      </div>
      <div className={legalStyles.description}>
        <Typography color="black" weight="medium">
          Informação que podemos partilhar
        </Typography>
      </div>
      <div className={legalStyles.description}>
        <table>
          <tr>
            <td>
              <Typography color="black" variant="smallBody" weight="medium">
                Categorias de Destinatários
              </Typography>
            </td>
            <td>
              <Typography color="black" variant="smallBody" weight="medium">
                Justificação para a Partilha de Dados Pessoais
              </Typography>
            </td>
          </tr>
          <tr>
            <td>
              <Typography color="black" variant="smallBody">
                Prestadores de Serviços
              </Typography>
            </td>
            <td>
              <Typography color="black" variant="smallBody">
                Colaboramos com prestadores de serviços que trabalham em nosso
                nome e que podem necessitar de aceder a determinados dados
                pessoais dos utilizadores para efeitos do fornecimento dos seus
                serviços. As mencionadas empresas incluem as empresas
                contratadas para prestar os serviços de apoio ao cliente, para
                operar a infraestrutura técnica necessária para disponibilizar
                os Serviços Sioslife, para ajudar a proteger e garantir os
                nossos sistemas e serviços, bem como para prestar assistência na
                comercialização dos produtos e serviços da Sioslife.
              </Typography>
            </td>
          </tr>
          <tr>
            <td>
              <Typography color="black" variant="smallBody">
                Processadores de pagamentos
              </Typography>
            </td>
            <td>
              <Typography color="black" variant="smallBody">
                Partilhamos os seus dados pessoais com os nossos processadores
                de pagamento conforme necessário por forma a permitir que estes
                últimos processem os seus pagamentos, bem como para efeitos de
                combate à fraude.
              </Typography>
            </td>
          </tr>
          <tr>
            <td>
              <Typography color="black" variant="smallBody">
                Parceiros publicitários
              </Typography>
            </td>
            <td>
              <Typography color="black" variant="smallBody">
                Colaboramos com parceiros publicitários, o que nos permite
                personalizar o conteúdo de publicidade que o utilizador pode
                receber nos Serviços Sioslife. Os referidos parceiros
                auxiliam-nos a apresentar os anúncios e as mensagens
                promocionais mais relevantes para o utilizador, desde
                publicidade com base em interesse (publicidade comportamental
                online), a publicidade contextual e publicidade genérica nos
                Serviços Sioslife. Juntamente com os nossos parceiros
                publicitários, é possível que processemos determinados dados
                pessoais para auxiliar a Sioslife a entender os seus interesses
                ou preferências, apresentando ao utilizador anúncios mais
                relevantes.
              </Typography>
            </td>
          </tr>
          <tr>
            <td>
              <Typography color="black" variant="smallBody">
                Investigadores Académicos
              </Typography>
            </td>
            <td>
              <Typography color="black" variant="smallBody">
                Partilhamos os seus dados pessoais para atividades como sejam a
                análise estatística e o estudo académico, mas apenas sob
                pseudónimo.
              </Typography>
            </td>
          </tr>
          <tr>
            <td>
              <Typography color="black" variant="smallBody">
                Outras Empresas do Grupo Sioslife
              </Typography>
            </td>
            <td>
              <Typography color="black" variant="smallBody">
                Partilhamos os seus dados pessoais com outras empresas do Grupo
                Sioslife no intuito de realizar as nossas operações comerciais
                diárias e para nos permitir manter e fornecer ao utilizador os
                Serviços Sioslife.
              </Typography>
            </td>
          </tr>
          <tr>
            <td>
              <Typography color="black" variant="smallBody">
                Autoridades de aplicação da lei e de proteção de dados pessoais
              </Typography>
            </td>
            <td>
              <Typography color="black" variant="smallBody">
                Partilhamos os seus dados pessoais quando, de boa fé,
                acreditamos que é necessário fazê-lo para efeitos de cumprimento
                de uma obrigação legal imposta ao abrigo da legislação
                aplicável, ou para resposta a um procedimento legal válido, como
                um mandato de busca, uma ordem judicial ou uma intimação.
                Partilhamos igualmente os seus dados pessoais quando, de boa-fé,
                acreditamos que é necessário fazê-lo para efeitos do nosso
                próprio interesse legítimo ou do interesse legítimo de terceiros
                em relação à segurança nacional, aplicação da lei, litígio,
                investigação criminal, proteção da segurança de qualquer pessoa
                ou para evitar a morte ou danos físicos iminentes, desde que
                consideremos que tal interesse não seja anulado pelos interesses
                do utilizador ou direitos e liberdades fundamentais que exijam a
                proteção dos seus dados pessoais.
              </Typography>
            </td>
          </tr>
          <tr>
            <td>
              <Typography color="black" variant="smallBody">
                Compradores da atividade comercial da Sioslife
              </Typography>
            </td>
            <td>
              <Typography color="black" variant="smallBody">
                Partilhamos os seus dados pessoais em casos de venda ou
                negociação da venda da nossa atividade comercial a um comprador
                ou potencial comprador. Nesta situação, a Sioslife continua a
                garantir a confidencialidade dos dados pessoais do utilizador e
                avisá-lo-á previamente à transmissão dos dados pessoais do
                utilizador para o comprador ou a que estes fiquem sujeitos a uma
                política de privacidade diferente.
              </Typography>
            </td>
          </tr>
        </table>
      </div>
      <div className={legalStyles.description}>
        <Typography color="black">
          A Sioslife assegura que a possível partilha de dados só será efetuada
          a entidades que revelem política de privacidade idêntica.
        </Typography>
      </div>
      <br />
      <br />
      <br />
      <Typography variant="h3" color="yellow" weight="medium">
        7. Conservação e eliminação de dados pessoais
      </Typography>
      <div className={legalStyles.description}>
        <Typography color="black">
          Procedemos à manutenção dos seus dados pessoais apenas enquanto os
          mesmos se revelem necessários para efeitos de lhe fornecer os Serviços
          Sioslife, bem como para fins comerciais legítimos e essenciais, tais
          como a manutenção do desempenho dos Serviços Sioslife, a tomada de
          decisões comerciais com base em dados sobre novas funcionalidades e
          ofertas, o cumprimento das nossas obrigações legais e a resolução de
          litígios.
        </Typography>
      </div>
      <div className={legalStyles.description}>
        <Typography color="black">
          Se assim o solicitar, eliminaremos ou tornaremos anónimos os seus
          dados pessoais para não mais ser identificado, a menos que sejamos
          legalmente autorizados ou obrigados a manter determinados dados
          pessoais, incluindo nas seguintes situações:
        </Typography>
      </div>
      <div className={legalStyles.description}>
        <ul>
          <li>
            <Typography color="black">
              No caso de existir um problema não resolvido relacionado com a sua
              conta, como um crédito pendente ou uma reclamação ou litígio em
              curso, procederemos à conservação dos dados pessoais necessários
              até que a questão se encontre resolvida;
            </Typography>
          </li>
          <li>
            <Typography color="black">
              Onde se revele necessário conservar os dados pessoais para efeitos
              das nossas obrigações legais, fiscais, de auditoria e
              contabilísticas, procederemos à conservação dos dados pessoais
              necessários durante o período exigido pela lei aplicável;
            </Typography>
          </li>
          <li>
            <Typography color="black">
              Sempre que necessário em função dos nossos interesses comerciais
              legítimos, tais como a prevenção de fraudes ou a manutenção da
              segurança dos nossos utilizadores.
            </Typography>
          </li>
        </ul>
      </div>
      <br />
      <br />
      <br />
      <Typography variant="h3" color="yellow" weight="medium">
        8. Transmissão de dados pessoais para outros países
      </Typography>
      <div className={legalStyles.description}>
        <Typography color="black">
          A Sioslife partilha os seus dados pessoais globalmente com as empresas
          do Grupo Sioslife para efeitos de realizar as atividades especificadas
          na presente Política. A Sioslife pode igualmente subcontratar o
          tratamento, ou partilhar os seus dados pessoais com terceiros
          localizados noutros países. Os dados pessoais do utilizador podem,
          portanto, encontrar-se sujeitos a leis de privacidade diferentes das
          aplicáveis no seu país.
        </Typography>
      </div>
      <div className={legalStyles.description}>
        <Typography color="black">
          Os dados pessoais recolhidos na União Europeia e na Suíça podem, por
          exemplo, ser transmitidos e processados por terceiros localizados num
          país fora da União Europeia e da Suíça. Nesses casos, a Sioslife deve
          garantir que a transmissão dos dados pessoais do utilizador seja
          realizada de acordo com as leis de privacidade aplicáveis e, em
          particular, que se encontrem em vigor todas as medidas contratuais,
          técnicas e organizacionais adequadas, tais como as Cláusulas
          Contratuais Gerais aprovadas pela Comissão Europeia.
        </Typography>
      </div>
      <br />
      <br />
      <br />
      <Typography variant="h3" color="yellow" weight="medium">
        9. Ligações
      </Typography>
      <div className={legalStyles.description}>
        <Typography color="black">
          Podemos exibir anúncios de terceiros e outros conteúdos que contenham
          ligações para sítios de terceiros. Não podemos, contudo, controlar ou
          ser responsabilizados pelas práticas de privacidade e conteúdos de
          terceiros. No caso de clicar num anúncio ou ligação de terceiros, note
          que está a abandonar os Serviços Sioslife e quaisquer dados pessoais
          que faculte não serão cobertos pela presente Política. Recomendamos
          que leia as respetivas políticas de privacidade para compreender a
          forma de recolha e tratamento dos seus dados pessoais por parte destes
          terceiros.
        </Typography>
      </div>
      <br />
      <br />
      <br />
      <Typography variant="h3" color="yellow" weight="medium">
        10. Manutenção da segurança dos seus dados pessoais
      </Typography>
      <div className={legalStyles.description}>
        <Typography color="black">
          Estamos empenhados em proteger os dados pessoais dos nossos
          utilizadores. Neste sentido, implementamos medidas técnicas e
          organizacionais apropriadas para auxiliar na proteção da segurança dos
          mencionados dados pessoais; no entanto, note que nenhum sistema é
          completamente seguro. Instauramos várias políticas, incluindo
          políticas de pseudonimização, criptografia, acesso e conservação para
          efeitos de proteger contra o acesso não autorizado e conservação
          desnecessária de dados pessoais nos nossos sistemas.
        </Typography>
      </div>
      <div className={legalStyles.description}>
        <Typography color="black">
          A sua palavra-passe protege a sua conta de utilizador, pelo que o
          encorajamos a usar uma palavra-passe forte que seja exclusiva da sua
          conta Sioslife, nunca a partilhando com ninguém, limitando o acesso
          aos seus dispositivos ou navegadores, bem como desligando devidamente
          os Serviços Sioslife após cada utilização.
        </Typography>
      </div>
      <br />
      <br />
      <br />
      <Typography variant="h3" color="yellow" weight="medium">
        11. Menores
      </Typography>
      <div className={legalStyles.description}>
        <Typography color="black">
          Os Serviços Sioslife não são especialmente direcionados a crianças com
          menos de 13 anos. Os Serviço Sioslife também não são oferecidos a
          crianças cuja idade torna ilegal o tratamento dos seus dados pessoais,
          ou requer o consentimento dos pais para o tratamento dos dados
          pessoais ao abrigo do RGPD ou outra lei local.
        </Typography>
      </div>
      <div className={legalStyles.description}>
        <Typography color="black">
          Não recolhemos conscientemente dados pessoais de crianças menores de
          13 anos ou com idade inferior ao limite aplicável (o &quot;Limite de
          Idade&quot;). Se a idade do utilizador for inferior ao Limite de
          Idade, não nos forneça quaisquer dados pessoais.
        </Typography>
      </div>
      <div className={legalStyles.description}>
        <Typography color="black">
          Se for pai/mãe de uma criança menor de idade e souber que o seu filho
          forneceu dados pessoais à Sioslife, queira entrar em contacto connosco
          através do formulário &quot;Contacte-nos&quot; disponível no nosso
          website, onde poderá exercer os seus direitos, conforme detalhados
          anteriormente na presente Política.
        </Typography>
      </div>
      <div className={legalStyles.description}>
        <Typography color="black">
          No caso de tomarmos conhecimento que estamos a recolher dados pessoais
          de uma criança com menos de 13 anos de idade, tomaremos medidas
          razoáveis para eliminar os dados pessoais em causa, o que poderá
          conduzir-nos a apagar a respetiva conta Sioslife.
        </Typography>
      </div>
      <br />
      <br />
      <br />
      <Typography variant="h3" color="yellow" weight="medium">
        12. Alterações à presente Política
      </Typography>
      <div className={legalStyles.description}>
        <Typography color="black">
          Poderemos ocasionalmente efetuar alterações à presente Política.
        </Typography>
      </div>
      <div className={legalStyles.description}>
        <Typography color="black">
          Quando realizarmos alterações materiais à presente Política,
          enviar-lhe-emos a respetiva notificação, conforme adequado e de acordo
          com as circunstâncias, por exemplo, através da exibição de um aviso
          visível nos Serviços Sioslife ou do envio de um mail e / ou uma
          notificação para o dispositivo do utilizador. Notificá-lo-emos com
          antecedência.
        </Typography>
      </div>
      <div className={legalStyles.description}>
        <Typography color="black">
          Certifique-se, pois, que lê atentamente qualquer aviso desta natureza
        </Typography>
      </div>
      <div className={legalStyles.description}>
        <Typography color="black">
          Para informações adicionais sobre a presente Política e relativamente
          à forma através da qual a Sioslife usa os dados pessoais do
          utilizador, queira entrar em contacto connosco, através de qualquer
          canal disponível para o efeito.
        </Typography>
      </div>
      <br />
      <br />
      <br />
      <Typography variant="h3" color="yellow" weight="medium">
        13. Como pode contactar-nos?
      </Typography>
      <div className={legalStyles.description}>
        <Typography color="black">
          Obrigado por ler a nossa Política. Se tiver alguma dúvida
          relativamente à presente Política, entre em contacto connosco através
          do e-mail{" "}
          <a href={launcher ? "#" : "mailto:compliance@sioslife.com"}>
            compliance@sioslife.com
          </a>{" "}
          ou formulário presente no nosso website -{" "}
          <a href={launcher ? "#" : "https://www.sioslife.com"}>
            https://www.sioslife.com
          </a>
          . Pode, também, enviar uma carta para a seguinte morada:
        </Typography>
      </div>
      <div className={legalStyles.description}>
        <Typography color="black">
          <a
            href={launcher ? "#" : "https://g.page/sioslife?share"}
            rel="noreferrer"
            target={launcher ? "" : "_blank"}
          >
            Rua Eng. Nuno Alvares Pereira, Nº 33
            <br />
            Gualtar 4710-915, Braga
          </a>
        </Typography>
      </div>
      <div className={legalStyles.description}>
        <Typography color="black">
          Sioslife é uma marca registada e gerida pela entidade Sioslife S.A.. A
          Sioslife S.A. é a entidade responsável pelo tratamento dos dados
          pessoais tratados ao abrigo da presente Política.
        </Typography>
      </div>
      <div className={legalStyles.description}>
        <Typography color="black">
          Esperamos que desfrute dos nossos serviços!
        </Typography>
      </div>
      <div className={legalStyles.description}>
        <Typography color="black">© Sioslife / Sioslife S.A.</Typography>
      </div>
    </LegalDocument>
  );
}

PrivacyPolicy.propTypes = {
  launcher: PropTypes.bool,
};

PrivacyPolicy.defaultProps = {
  launcher: false,
};

export default PrivacyPolicy;
